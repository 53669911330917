.infomationUpdatePages {
    table {
        border: 1px solid gray;
        width: 80%;
        margin: auto;
    }

    table td {
        border: 1px solid gray;
        padding: 2px 6px;
    }
}
