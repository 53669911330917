@tailwind base;
@tailwind components;
@tailwind utilities;

svg {
    vertical-align: initial;
}

img {
    display: inline-block;
}

@font-face {
    font-family: 'SuCaiJiShiKuFangTi';
    src: url('assets/SuCaiJiShiKuFangTi-2.ttf');
    font-weight: normal;
}

::-webkit-scrollbar {
    display: none;
}

.ant-menu-submenu-disabled,
.ant-menu-item-disabled {
    display: none !important;
}

.ant-image-preview-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-segmented-item-selected > .ant-segmented-item-label {
    color: var(--ant-primary-color);
}

.ant-form-inline > .ant-form-item {
    margin-right: 0 !important;
}

.textOVerThree {
    display: -webkit-box;
    overflow: hidden;
    white-space: normal !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
